<template>
  <div>
    <v-sheet class="pa-2 pa-md-4 rounded-lg elevation-1">
      <v-card-title>Фотографии объекта</v-card-title>
      <v-card-text class="mb-2">
        <div
          v-if="!editing && (!value.media || value.media.length == 0)"
          class="text-center pa-4 pb-12"
        >
          Нет фото
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-4"  v-for="(mfile, index) in value.media" :key="mfile.id">
            <v-menu absolute>
              <template v-slot:activator="{ on }">
                <div
                  style="position: relative;"
                  class="upload-thumbnail"
                  :key="mfile.id"
                >
                  <v-img
                    aspect-ratio="1.5"
                    v-if="editing"
                    v-on="on"
                    class="grey lighten-2 rounded"
                    :src="getMediaPreviewUrl(mfile)"
                  ></v-img>
                  <v-img
                    aspect-ratio="1.5"
                    v-else
                    @click="openGallery(mfile.id)"
                    class="grey lighten-2 rounded"
                    :src="getMediaPreviewUrl(mfile)"
                  ></v-img>
                </div>
              </template>
              <v-list>
                <div class="px-2">
                  <div class="px-2 text-body-2">Порядок</div>
                  <v-btn large :disabled="index === 0" icon @click="moveTo(mfile.id, 0)" class="rounded-0">
                    <v-icon>mdi-chevron-double-left</v-icon>
                  </v-btn>
                  <v-btn large :disabled="index === 0" icon @click="moveTo(mfile.id, index-1)"  class="rounded-0">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn large :disabled="index === value.media.length - 1" icon @click="moveTo(mfile.id, index+1)" class="rounded-0">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                  <v-btn large :disabled="index === value.media.length - 1" icon @click="moveTo(mfile.id, value.media.length - 1)" class="rounded-0">
                    <v-icon>mdi-chevron-double-right</v-icon>
                  </v-btn>
                </div>
                <v-divider class="my-1"/>
                <v-list-item @click="openGallery(mfile.id)">Открыть</v-list-item>
                <v-list-item @click="removeItem(mfile)">Удалить</v-list-item>
              </v-list>
            </v-menu>
          </div>
          <div
            v-for="upl in showLoading"
            :key="upl.id"
            v-show="upl.uploading"
            style="position: relative;"
            class="col-12 col-md-4 upload-thumbnail"
          >
            <v-img class="grey lighten-2 rounded" aspect-ratio="1.5"></v-img>
            <div class="progress-circular">
              <v-progress-circular
                style="width: 100%; height: 100%;"
                :width="2"
                color="white"
                :value="upl.progress * 100"
              />
            </div>
          </div>
          <label v-if="editing" class="col-12 col-md-4" for="files">
            <v-responsive aspect-ratio="1.5">
              <div
                style="width: 100%; height: 100%"
                @drop.prevent
                @dragover.prevent
                class="pa-0 drop-area d-flex flex-column justify-center align-center rounded"
                fluid
              >
                <v-icon size="32">
                  mdi-camera-outline
                </v-icon>
                <span class="text-center medium-font">
                  Добавить фото
                </span>
                <span class="text--secondary mt-2"> не больше 10 Мб </span>
              </div>
            </v-responsive>
          </label>
          <input
            accept="image/png, image/jpeg"
            type="file"
            multiple
            style="display: none;"
            id="files"
            @change="filesChange"
          />
        </div>
      </v-card-text>
    </v-sheet>
  </div>
</template>

<script>
import datetime from "@/utils/datetime";
import opencity from "../../service/opencity";

import PhotoSwipe from "photoswipe";
import "photoswipe/dist/photoswipe.css";

export default {
  props: {
    editing: {
      type: Boolean
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      selection: 0,
      uploading: [],
      counter: 0
    };
  },
  computed: {
    teamId() {
      return this.$route.params.id;
    },
    showLoading() {
      return this.uploading.filter(el => el.uploading);
    }
  },
  methods: {
    moveTo(fileId, position) {
      const index = this.value.media.findIndex(el => el.id == fileId);
      if (position < 0 || position >= this.value.media.length) return;
      if (index === position) return;
      const tmp = this.value.media[index];
      const tmpa = [...this.value.media]
      tmpa.splice(index, 1);
      tmpa.splice(position, 0, tmp);
      this.value.media = tmpa;

    },
    getMediaUrl(file) {
      return opencity.getMediaUrl(file);
    },
    getMediaPreviewUrl(file) {
      return opencity.getMediaPreviewUrl(file);
    },
    openGallery(fileId) {
      const dataSource = this.value.media.map(el => ({
        src: this.getMediaUrl(el),
        width: el.width,
        height: el.height
      }));

      const index = this.value.media.findIndex(el => el.id === fileId);
      const photoSwipe = new PhotoSwipe({
        index,
        dataSource,
        wheelToZoom: true
      });
      photoSwipe.init();
    },
    removeItem(fileId) {
      const index = this.value.media.indexOf(fileId);
      if (index !== -1) {
        this.value.media.splice(index, 1);
      }
    },
    filesChange(ev) {
      for (let i = 0; i < ev.target.files.length; i++) {
        this.addFile(ev.target.files[i]);
      }
    },
    addFile(file) {
      const obj = {
        file,
        uploading: false,
        error: null,
        progress: 0,
        xhr: null,
        id: this.counter++
      };
      this.uploading.push(obj);
      this.uploadFile(obj);
    },
    async uploadFile(file) {
      file.uploading = true;
      file.error = null;
      file.progress = 0;

      const xhr = new XMLHttpRequest();
      var formData = new FormData();
      formData.append("file", file.file);
      file.xhr = xhr;

      const success = await new Promise(resolve => {
        xhr.upload.addEventListener("progress", event => {
          if (event.lengthComputable) {
            file.progress = event.loaded / event.total;
            // console.log("upload progress:", file.progress);
          }
        });
        xhr.addEventListener("loadend", () => {
          file.realId = JSON.parse(xhr.responseText).fileName;
          resolve(xhr.readyState === 4 && xhr.status === 200);
        });
        xhr.open("POST", opencity.API_BASE + "/files/", true);
        xhr.setRequestHeader(
          "Authorization",
          opencity.AUTHORIZATION_PREFIX + opencity.access_token
        );
        xhr.setRequestHeader("Response-Type", "application/json");
        xhr.send(formData);
      });

      file.uploading = false;
      if (!this.value.media) this.value.media = [];
      this.value.media.push({ id: file.realId });
    }
  },
  filters: {
    datetime
  }
};
</script>

<style lang="scss">
.drop-area {
  border: 2px dashed rgba(65, 66, 73, 0.5);
  //transition: border 2s linear;
  > .v-icon {
    opacity: 0.5;
  }
  &:hover {
    > .v-icon {
      opacity: 0.8;
    }
    border: 2px dashed rgba(65, 66, 73, 0.8);
  }
  box-sizing: border-box;
  height: 236px;
  cursor: pointer;
  user-select: none;
  @media (min-width: 960px) {
    height: 400px;
  }
}

.choice-btn-active.v-btn--disabled .v-btn__content {
  color: black;
}
.map-block .mapboxgl-map {
  position: relative;
}

.mapboxgl-canvas-container {
  height: 400px;
  //  overflow: hidden;
}

.data-value {
  text-overflow: ellipsis;
}

.upload-thumbnail {
  cursor: pointer;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .progress-circular {
    position: absolute;
    padding: 8px;
    top: 12px;
    left: 12px;
    bottom: 12px;
    right: 12px;

    background-color: #00000044;
    pointer-events: none;
  }
}
</style>
