<template>
  <v-row class="justify-center">
    <v-col class="card-grid">
      <v-sheet elevation="1" class="card-grid-card">
        <v-card-title class="d-flex">
          <span>Основные данные</span>
        </v-card-title>
        <v-card-text>
          <v-label>
            Название:
            <div v-if="!editing" class="data-value" v-text="value.fullName" />
            <v-text-field v-else v-model="value.fullName" outlined dense />
          </v-label>
          <v-label>
            Расположение:
            <div class="data-value">
              <p v-text="value.location || '—'"></p>
            </div>
            <router-link v-if="staticImgSrc" to="#geo">
              <v-img
                class="my-4 grey lighten-2 rounded"
                :src="staticImgSrc"
                height="220"
              >
              </v-img>
            </router-link>
            <v-btn class="my-4" color="primary" v-else to="#geo" text block>Показать фигуру</v-btn>
            <div class="data-value" v-if="value.parentObject">
              В составе объекта
              <router-link :to="`/service/objects/${value.parentObject.id}`"
                ><b>{{ value.parentObject.title }}</b></router-link
              >
            </div>
          </v-label>
          <v-label>
            Статус:
            <div class="data-value" v-text="value.onModeration ? 'На модерации' : 'Опубликован'"></div>
          </v-label>
        </v-card-text>
      </v-sheet>
    </v-col>
    <v-col class="card-grid">
      <v-sheet class="card-grid-card" elevation="1">
        <v-card-title>Детали</v-card-title>
        <v-card-text>
          <router-link to="#media">
            <OCMediaPreview disable-gallery :media="value.media" :height="220" />
          </router-link>
          <v-label>
            Примечание:
            <ExpandableParagraph v-if="!editing" :text="value.note || '—'" />
            <v-textarea
              v-else
              v-model="value.note"
              outlined
              dense
              rows="3"
              auto-grow
            ></v-textarea>
          </v-label>
          <v-label>
            Видимость:
            <div
              v-if="!editing"
              class="data-value"
              v-text="value.visible ? 'Для всех' : 'Для команды'"
            ></div>
            <v-select
              v-else
              item-text="label"
              item-value="value"
              v-model="value.visible"
              dense
              outlined
              :items="[
                { value: true, label: 'Для всех' },
                { value: false, label: 'Для команды' }
              ]"
            >
            </v-select>
          </v-label>
        </v-card-text>
      </v-sheet>
      <v-sheet class="card-grid-card" elevation="1">
        <v-card-title
          >Активность</v-card-title
        >
        <v-card-text>
          <div class="activity-link">Работы (0)</div>
          <div class="activity-link">Сообщения жителей (0)</div>
          <div class="activity-link">Мероприятия (0)</div>
        </v-card-text>
      </v-sheet>
      <v-sheet class="card-grid-card" elevation="1">
        <v-card-text>
          <template v-if="value.author">
            <v-label>Автор</v-label>
            <div class="mb-3">
              <v-btn text class="ml-n2 px-2 py-2">
                <v-avatar class="mr-2" color="teal" size="28">
                  <span class="white--text header">{{
                    value.author.firstName[0]
                  }}</span>
                </v-avatar>
                <span class="body-1">
                  {{ value.author.fullName }}
                </span>
              </v-btn>
            </div>
          </template>
          <v-label> Дата создания </v-label>
          <div class="mb-3">
            <span class="body-1 mb-4" :title="fulldatetime(value.createdAt)" tabindex="0">
              {{ datetime2(value.createdAt) }}
            </span>
          </div>
          <v-label> Дата изменения </v-label>
          <div class="mb-1">
            <span class="body-1" :title="fulldatetime(value.createdAt)" tabindex="0">
              {{ datetime2(value.updatedAt) }}
            </span>
          </div>
        </v-card-text>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
import {datetime2, fulldatetime} from "@/utils/datetime";

import opencity, {toText} from "../../service/opencity";
import ExpandableParagraph from "@/components/ExpandableParagraph.vue";
import NoPhoto from "@/components/NoPhoto.vue";
import OCMediaPreview from "@/components/object/OCMediaPreview";
import useClassifier from "@/mixins/useClassifier";

export default {
  components: {OCMediaPreview, NoPhoto, ExpandableParagraph},
  props: {
    value: {
      type: Object
    },
    editing: {
      type: Boolean
    }
  },
  data() {
    return {
      selection: 0,
      showEco: false
    };
  },
  methods: {
    datetime2, fulldatetime
  },
  mixins: [useClassifier],
  computed: {
    teamId() {
      return this.$route.params.id;
    }
  },
};

// :to="`/user/${value.author.id}`"
</script>

<style lang="scss">
.choice-btn-active.v-btn--disabled .v-btn__content {
  color: black;
}

.map-block .mapboxgl-map {
  position: relative;
}

.mapboxgl-canvas-container {
  height: 400px;
}

.v-label {
  font-size: 0.825rem;
  .data-value {
    line-height: normal;
    white-space: pre-line;
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 1rem;
    .theme--dark & {
      color: #fff;
    }
  }
}

.activity-link {
  text-decoration: none;
  margin-bottom: 4px;
  display: block;
  font-size: 0.925rem;
}

.static-marker {
  position: absolute;
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  background: green;
  height: 16px;
  border-radius: 100%;
  border: 2px solid white;
}

.v-label textarea {
  max-height: 260px;
  overflow-y: auto;
}

.v-slide-group__prev,
.v-slide-group__next {
  display: none !important;
}

.w-50 {
  width: 50%;
}
</style>
