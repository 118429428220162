<template>
  <div>
    <v-sheet class="pa-2 pa-md-4 rounded-lg" elevation="1">
      <v-card-title>Параметры</v-card-title>
      <v-card-text>
        <v-form
          class="col-12 col-md-6 px-0"
          ref="form"
          v-if="categoryAsset.schema"
        >
          <template v-for="field in categoryAsset.schema.fields">
            <v-label :key="field.key">
              <div class="d-flex justify-space-between w-100">
                <span>
                  {{
                    field.name +
                      (field.constraints && field.constraints.required
                        ? "*"
                        : "")
                  }}
                </span>
                <v-icon size="16">mdi-web</v-icon>
              </div>
              <div
                class="data-value"
                v-if="!editing || (field.constraints && field.constraints.auto)"
                v-text="getFieldValue(field, value.data[field.key])"
              />
              <v-text-field
                outlined
                dense
                v-else-if="field.type === 'string'"
                v-model="value.data[field.key]"
              />
              <v-text-field
                v-else-if="field.type === 'number'"
                v-model="value.data[field.key]"
                outlined
                dense
                type="number"
              />
              <v-select
                v-else-if="field.type === 'option'"
                v-model="value.data[field.key]"
                :items="field.options"
                outlined
                dense
              />
              <GeneraSelectfield
                v-else-if="field.type === 'genera'"
                :field="field"
                :value="value"
              />
              <SpeciesSelectField
                v-else-if="field.type === 'species'"
                :field="field"
                :value="value"
              />
              <v-autocomplete
                v-else-if="field.type === 'multiple_option'"
                v-model="value.data[field.key]"
                :items="getGroupItems(field.taxonomy)"
                multiple
                item-text="name"
                return-object
                outlined
                dense
                class="w-100"
              >
              </v-autocomplete>
              <v-text-field
                v-else
                outlined
                dense
                disabled
                filled
                :value="value.data[field.key] + '(Неизвестный тип поля)'"
                error
                class="w-100"
              ></v-text-field>
            </v-label>
            <v-divider v-show="!editing" :key="field.key" class="mb-4" />
          </template>
        </v-form>

        <v-form v-else class="col-12 col-md-6 px-0">
          <v-label>
            <div class="d-flex justify-space-between w-100">
              <span>
                Площадь (м²)
              </span>
              <v-icon size="16">mdi-web</v-icon>
            </div>
            <div
              class="data-value"
              v-if="!editing"
              v-text="value.data.area || '–'"
            ></div>
            <v-text-field
              v-else
              v-model="value.data.area"
              outlined
              dense
              type="number"
            ></v-text-field>
          </v-label>
          <v-divider v-if="!editing" class="mb-4" />

          <v-label>
            <div class="d-flex justify-space-between w-100">
              <span>
                Назначение
              </span>
              <v-icon size="16">mdi-web</v-icon>
            </div>
            <div
              class="data-value"
              v-if="!editing"
              v-text="value.data.purpose || '–'"
            ></div>
            <v-text-field
              v-else
              v-model="value.data.purpose"
              outlined
              dense
            ></v-text-field>
          </v-label>
        </v-form>
      </v-card-text>
    </v-sheet>
  </div>
</template>
<script>
import opencity from "../../service/opencity";
import categories from "@/assets/categories";
import GeneraSelectfield from "@/views/Object/GeneraSelectfield.vue";
import SpeciesSelectField from "@/views/Object/SpeciesSelectField.vue";

export default {
  components: { SpeciesSelectField, GeneraSelectfield },
  props: {
    editing: {
      type: Boolean
    },
    value: {
      type: Object
    }
  },
  computed: {
    categoryAsset() {
      const types = categories.reduce((o, el) => [...o, ...el.topics], []);
      return types.find(el => el.id === this.value.objectType.id);
    }
  },
  methods: {
    getFieldValue(field, value) {
      if (field.type === "multiple_option") {
        return value?.map(el => el.name).join("\n") || "–";
      }
      if (field.type === "genera") {
        return value?.name || "–";
      }

      return value || "–";
    },
    getGroupItems(taxonomy) {
      return opencity.getTaxonomy(taxonomy).reduce((o, el) => {
        el.items.forEach(l => o.push({ name: l, group: el.group }));
        return o;
      }, []);
    }
  }
};
</script>

<style lang="scss">
.choice-btn-active.v-btn--disabled .v-btn__content {
  color: black;
}
.map-block .mapboxgl-map {
  position: relative;
}

.mapboxgl-canvas-container {
  height: 400px;
  //  overflow: hidden;
}

.data-value {
  text-overflow: ellipsis;
}
</style>
